import { backend_api } from '@/utils/api'
import bus from '@/utils/event_bus'


export default {
    namespaced: true,
    state: {
        access_token: localStorage.getItem('access_token'),
        refresh_token: localStorage.getItem('refresh_token'),
        authenticated: !!localStorage.getItem('access_token'),
        user_object: {
            _flag: "not-initialized",
            username: "",
            auth: {}
        }
    },
    mutations: {
        setTokens(state, data) {
            state.access_token = data.access
            state.refresh_token = data.refresh
            state.authenticated = true
            localStorage.setItem('access_token', state.access_token)
            localStorage.setItem('refresh_token', state.refresh_token)
        },
        refreshToken(state, at) {
            state.access_token = at
            localStorage.setItem('access_token', state.access_token)
        },
        cleanSession(state) {
            state.access_token = ""
            state.refresh_token = ""
            state.authenticated = false
            localStorage.setItem('access_token', "")
            localStorage.setItem('refresh_token', "")
        },
        setUserObject(state, data) {
            state.user_object = data
        }
    },
    actions: {
        login(context, data) {
            return backend_api.post('/token/', data)
                .then(({ data }) => {
                    context.commit('setTokens', data)
                    context.dispatch('refreshBaseData', true, { root: true })
                })
        },
        refresh(context) {
            console.log("[i] Refreshing access token")
            return backend_api.post('/token/refresh/', { refresh: context.state.refresh_token })
                .then(({ data }) => {
                    context.commit('refreshToken', data.access)
                    context.dispatch('refreshBaseData', true, { root: true })
                })
                .catch(() => {
                    bus.$emit("notification", {
                        timeout: 1000,
                        text: "Failed to refresh session. You have to login.",
                        color: "error"
                    });
                })
        },
        getUserObject(context) {
            return backend_api.get('/my_user/')
                .then(({ data }) => {
                    context.commit('setUserObject', data)
                })
                .catch(e => console.log(`Cant get User Object: ${e}`))
        },
    },
    getters: {
        my_company: state => state.user_object.auth?.company,
    }
}