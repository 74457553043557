<template>
    <v-container fluid>
        <v-row>
            <v-col>
                Login out...
            </v-col>
        </v-row>
    </v-container>
</template>


<script>
export default {
    methods: {
        logout() {
            this.$store.commit("session/cleanSession");
            this.$router
                .push("/login")
                .catch(() =>
                    console.log(
                        "[!] Can't go to login. Maybe already in login page."
                    )
                );
        },
    },
    mounted() {
        this.logout()
    }
}
</script>
