<template>
    <v-container class="noti-box">
        <v-layout column>
            <v-flex width="200">
                <v-alert v-for="not in notifications" :key="not.uuid" :value="true" :type="not.color || 'info'"
                    @click="destroy(not)" bottom right class="noti">{{ not.text }}</v-alert>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<style lang="scss" scoped>
.noti {
    pointer-events: auto;
}

.noti-box {
    width: 400px;
    pointer-events: none;
    position: fixed;
    bottom: 0px;
    right: 0px;
    z-index: 1000;
}
</style>
<script>
import bus from "@/utils/event_bus";
export default {
    data: () => ({
        show: true,
        notifications: [],
    }),
    computed: {},
    methods: {
        destroy(index) {
            this.notifications.splice(index, 1);
        },
        test_not() {
            this.notificate({
                text: "Testing notifications!",
                color: "error",
                timeout: 5000,
            });
        },
        notificate(n) {
            // console.log("NOTIFICATION:", { ...n, uuid: Date.now() });
            let duplicate = false;
            this.notifications.forEach((on) => {
                if (n.text == on.text && n.color == on.color) {
                    duplicate = true;
                }
            });
            if (!duplicate) {
                this.notifications.push({ ...n, uuid: Date.now() });
                setTimeout(() => this.destroy(n), n.timeout ? n.timeout : 6000);
            }
        },
    },
    mounted() {
        bus.$on("notification", (n) => this.notificate(n));
    },
    beforeDestroy() {
        bus.$off("notification");
    },
};
// EMIT NOTIFICATIONS LIKE THIS
// import bus from '@/utils/event_bus'
// bus.$emit('notification', {timeout: 5000, text:"Testing notifications!", color:"success"})
</script>