<template>
    <v-dialog v-model="dialog" width="400">
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" text color="accent">
                2FA SETUP
            </v-btn>
        </template>

        <v-card>
            <v-card-title> 2FA Setup </v-card-title>

            <v-card-text>
                Scan with your 2FA app (Authy, Google Authenticator)
                <br />
                <v-skeleton-loader v-if="loading" type="image"></v-skeleton-loader>
                <qrcode v-else :value="otp_uri" :options="{ width: 350 }" tag="img" id="qr-image"></qrcode>
                <br />
                <v-text-field v-model="otp" class="mr-7" label="ENTER OTP HERE TO CONFIRM" outlined
                    prepend-icon="mdi-qrcode" :error="error"></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="opt_enabled">
                    Clear
                </v-btn>
                <v-btn :disabled="loading" :loading="loading" v-else color="success" @click="save">
                    Save
                </v-btn>
                <v-btn color="secondary" @click="dialog = false">
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
import { backend_api } from "@/utils/api";
export default {
    data: () => ({
        loading: true,
        dialog: false,
        otp_uri: "",
        otp: "",
        error: false,
    }),
    computed: {
        user() {
            return this.$store.state.session.user_object;
        },
        opt_enabled() {
            return this.user.auth.otp_enabled;
        },
    },
    watch: {
        dialog(d) {
            if (d && !this.opt_enabled) this.get_new_secret();
        },
        otp() {
            this.error = false;
        },
    },
    methods: {
        get_new_secret() {
            this.loading = true;
            return backend_api
                .get("/my_user/new_otp_secret/")
                .then(({ data }) => {
                    this.otp_uri = data.otp_uri;
                })
                .catch((e) => console.log(`Cant load new OTP Secret: ${e}`))
                .finally(() => (this.loading = false));
        },
        save() {
            this.loading = true;
            return backend_api
                .post("/my_user/check_otp/", { otp: this.otp })
                .then(() => {
                    this.dialog = false;
                    this.$store.dispatch("session/getUserObject");
                })
                .catch((e) => {
                    console.log(`Cant check OTP Secret: ${e}`);
                    this.error = true;
                })
                .finally(() => (this.loading = false));
        },
    },
};
</script>