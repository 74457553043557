import BUILD_VERSION from '@/utils/version';

let DEFAULTS = {}

DEFAULTS.VUE_APP_ROOT_API = process.env.VUE_APP_ROOT_API
DEFAULTS.BUILD_VERSION = BUILD_VERSION

// DEFAULTS.IS_ANDROID = true

export default DEFAULTS
