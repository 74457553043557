import Vue from 'vue'
import Vuex from 'vuex'
import sessionModule from './session'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
    },
    mutations: {
    },
    actions: {
        setStateFromRoute: (context, route) => {
            console.log("[i] Setting state from url")
            console.log("THIS ROUTE:", route)
            console.log("THIS STATE:", context.state)

            // Get url params
            // let project_uuid = route.params.project_uuid;
        }
    },
    getters: {
    },
    modules: {
        session: sessionModule,
    }
})
