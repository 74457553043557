import Vue from 'vue'
import VueRouter from 'vue-router'
import ViewLanding from '@/components/ViewLanding.vue'
import ViewAccount from '@/components/account/ViewAccount.vue'
import ViewAdmin from '@/components/admin/ViewAdmin.vue'
import ViewLogin from '@/components/session/ViewLogin.vue'
import ViewLogout from '@/components/session/ViewLogout.vue'
import store from '@/store'
import bus from '@/utils/event_bus'
import multiguard from 'vue-router-multiguard';
// import INITIAL_STATE from "@/utils/defaults";

Vue.use(VueRouter)


const onlyAdminsGuard = function (to, from, next) {
    let auth = store.state.session.user_object.auth
    if (auth.full_admin || auth.is_superuser) {
        next()
    } else {
        bus.$emit('notification', { timeout: 3000, text: "You don't have permissions to enter this view. Contact your company admin", color: "error" })
        next(false)
    }
}


const hasSessionObjectGuard = function (to, from, next) {
    if (store.state.session.user_object._flag == "not-initialized") {
        store.dispatch('session/getUserObject')
            .then(() => {
                next()
            })
    } else {
        next()
    }
}

const check_login_subroutine = function (to, next) {
    // Auth lock
    if (store.state.session.authenticated) {
        console.log("is auth")
        next()
    } else {
        console.log("here add prop redirect to the next route")
        next({ name: 'login', params: { redirect_route: to.path } })
    }
}

const isAuthenticated = function (to, from, next) {

    if (store.state.session.user_object._flag == "not-initialized") {
        // Get user
        console.log("[i] Initializing user object.")
        store.dispatch('session/getUserObject')
            .then(() => {
                console.log("checking")
                check_login_subroutine(to, next)
            })

    } else {
        check_login_subroutine(to, next)
    }


}




const routes = [
    {
        path: '/',
        name: 'landing',
        component: ViewLanding,
        beforeEnter: multiguard([isAuthenticated]),
    },
    {
        path: '/login',
        name: 'login',
        component: ViewLogin,
        props: true,
    },
    {
        path: '/logout',
        name: 'logout',
        component: ViewLogout,
    },
    {
        path: '/admin',
        name: 'admin',
        component: ViewAdmin,
        beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard, onlyAdminsGuard])
    },
    {
        path: "/account",
        name: "account",
        component: ViewAccount,
        beforeEnter: multiguard([isAuthenticated, hasSessionObjectGuard])
    },
]

const router = new VueRouter({
    mode: 'history',
    base: '/app/',
    routes
})

router.beforeEach((to, from, next) => {
    // TODO IS THIS NEEDED????????
    // store.dispatch("setStateFromRoute", to)
    next()
})

export default router


